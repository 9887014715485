import React from 'react'
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">
          ANDRIJAN OSTRUN
        </h1>

        <p className="description">
          I'm a Software Engineer specializing in Distributed Ledger Technology (Blockchain), Web & IoT Development. 
          To see my latest work check out my GitHub page, and send me a mail if you want to get in touch, both of them linked bellow.
        </p>

        <div className="icons-container">
          {/* <a href="https://www.linkedin.com/in/andrijan-ostrun/" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faLinkedin} size="lg"/> </a> */}
          <a href="https://www.github.com/aostrun" title="GitHub" target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faGithub} size="lg" /> </a>
          <a href="mailto: andrijan.ostrun@gmail.com" title="Email"> <FontAwesomeIcon icon={faEnvelope} size="lg" /> </a>
        </div>
        
      </header>

      <footer className="App-footer">
        <a href="/contact" title="Contact" rel="noreferrer">Contact</a>
      </footer>
    </div>
  )
}
