import React from 'react'

export const Contact = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="title">
          CONTACT
        </h1>

        <div className="contact-list">
          <div className="contact-list-item">
            <div className="contact-list-item-value">
              {"Send me a mail @ "}
              <a href="mailto: andrijan.ostrun@gmail.com">andrijan.ostrun@gmail.com</a>
            </div>
          </div>

          <div className="contact-list-item">
            <div className="contact-list-item-value">
              {"Checkout my public work @ "}
              <a href="https://www.github.com/aostrun" target="_blank" rel="noreferrer">github.com/aostrun</a>
            </div>
          </div>

          <div className="contact-list-item">
            <div className="contact-list-item-value">
              {"Follow me @ "}
              <a href="https://www.twitter.com/andrijan_ostrun" target="_blank" rel="noreferrer">twitter.com/andrijan_ostrun</a>
            </div>
          </div>
        </div>
        
      </header>

      <footer className="App-footer">
        <p style={{fontSize: "12px", maxWidth: "95%", margin: "auto"}}>
          {"LEDGER LAB, obrt za računalne usluge, vl. Andrijan Ostrun, 10430 Samobor, Marka Vukasovića 21, OIB 45972121933. Žiro račun IBAN HR0323600001102832989 otvoren u Zagrebačka banka d.d."}  
        </p>
      </footer>
    </div>
  )
}
